import React from "react";
import { CartProvider } from "../../contexts/CartContext";
import { DialogProvider } from "../../contexts/DialogContext";

const RootElement = ({ children }) => {
  return (
    <CartProvider>
      <DialogProvider>{children}</DialogProvider>
    </CartProvider>
  );
};

export default RootElement;
