import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  // Initialize the cart from localStorage when the component mounts
  useEffect(() => {
    // Check if window is defined (so if in the browser and not on the server)
    if (typeof window !== "undefined") {
      const initialCart = JSON.parse(localStorage.getItem("cart")) || [];
      setCart(initialCart);
    }
  }, []);

  // Update localStorage whenever the cart state changes
  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  // Listen for changes in localStorage to update the cart in real-time
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedCart = JSON.parse(localStorage.getItem("cart")) || [];
      setCart(updatedCart);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return <CartContext.Provider value={{ cart, setCart }}>{children}</CartContext.Provider>;
};

export default CartContext;
