import React, { createContext, useContext, useState } from "react";

const DialogContext = createContext();

export const useDialog = () => {
  return useContext(DialogContext);
};

export const DialogProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [button, setButton] = useState("");
  const [type, setType] = useState("");

  const showDialog = (text, type, button) => {
    setMessage(text);
    setIsVisible(true);
    setType(type);
    setButton(button);
  };

  const hideDialog = () => {
    setMessage("");
    setIsVisible(false);
    setType("");
    setButton("");
  };

  return (
    <DialogContext.Provider value={{ isVisible, setIsVisible, message, setMessage, button, setButton, type, setType, showDialog, hideDialog }}>{children}</DialogContext.Provider>
  );
};
